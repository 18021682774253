<template>
  <main>
    <b-row>
      <b-col
        sm="12"
        class="text-right"
      >
      <locale />
      </b-col>
    </b-row>
    <b-row
      class="d-flex justify-content-center"
    >
      <b-col
        sm='12'
        class="text-center pb-1"
      >
      <!-- Brand logo-->
      <b-link>
        <img class="img_logo" :src="logo" alt="logo">
      </b-link>
      <!-- /Brand logo-->
      </b-col>
    </b-row>
  
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner mainrowtxt">

      <!-- Left Text-->
      <b-col
        lg="7"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-2">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="5"
        class="d-flex align-items-center auth-bg px-2 p-xl-5 p-lg-2"
      >
        <b-col
          sm="11"
          md="10"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            <span class="welcome">{{$t('message.welcome_to')}}</span> <span class="sft_title">Safetravel.today!</span> 👋
          </b-card-title>
          <b-card-text class="mb-2 signintxt">
            {{$t('message.adventure')}}
          </b-card-text>

          <b-alert
            variant="primary"
            show
          >
            <div class="alert-body font-small-2">
              <article>
                <p class="lead">Demo</p>
                <p class="mr-50 lead"><span>{{$t('message.username')}}:</span> demo | <span>{{$t('message.password')}}:</span> 123456!@#</p>
              </article>
            </div>
            <feather-icon
              v-b-tooltip.hover.left="'Demo Account'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10;"
            />
          </b-alert>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{$t('message.password')}}</label>
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>{{$t('message.forgot_pass')}}</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  {{$t('message.remember_me')}}
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                {{$t('message.sign_in')}}
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
  </main>
</template>

<script>
/* eslint-disable global-require */
import Locale from '../components/Locale.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    ValidationProvider,
    ValidationObserver,
    Locale
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      logo: require('@/assets/images/quotation/sft_logo.svg'),
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
        const crd = {
          "username":this.userEmail,
          "password":this.password
        }
        const errorMessage = {
          email: "Email or Password is Invalid"
        }
  
        fetch('https://api-quote.safetravel.today/v2/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(crd)
        })
        .then((response)=> {
          return response.json();
        })
        .then((res)=> {
          if (res) {
            if (!res.jwt) {
              this.$refs.loginForm.setErrors(errorMessage)
            }
            else {
              useJwt.login({
                email: null,
                password: null,
                details: res.jwt
              })
                .then(response => {
                  const { userData } = response.data
                  useJwt.setToken(response.data.accessToken)
                  useJwt.setRefreshToken(response.data.refreshToken)
                  this.$ability.update(userData.ability)
                  // SAVE USER DATA TO VUEX
                  this.$store.commit('app/USER_DATA', userData)
                  // ? This is just for demo purpose as well.
                  // ? Because we are showing eCommerce app's cart items count in navbar
                  this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

                  // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                  this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Welcome ${userData.fullName || userData.username}`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                        },
                      })
                    })
                })
                .catch(error => {
                  console.error(error)
                  this.$refs.loginForm.setErrors(error.response.data.error)
                })
            }
          } else {
            this.$refs.loginForm.setErrors(errorMessage);
          }
        })
        .catch((err)=> {
          console.error(err);
          this.$refs.loginForm.setErrors(errorMessage)
        })


      // this.$refs.loginForm.validate().then(success => {
      //   if (success) {
      //     useJwt.login({
      //       email: this.userEmail,
      //       password: this.password,
      //     })
      //       .then(response => {
      //         const { userData } = response.data
      //         useJwt.setToken(response.data.accessToken)
      //         useJwt.setRefreshToken(response.data.refreshToken)
      //         this.$ability.update(userData.ability)

      //         // ? This is just for demo purpose as well.
      //         // ? Because we are showing eCommerce app's cart items count in navbar
      //         this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

      //         // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
      //         this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
      //           .then(() => {
      //             this.$toast({
      //               component: ToastificationContent,
      //               position: 'top-right',
      //               props: {
      //                 title: `Welcome ${userData.fullName || userData.username}`,
      //                 icon: 'CoffeeIcon',
      //                 variant: 'success',
      //                 text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
      //               },
      //             })
      //           })
      //       })
      //       .catch(error => {
      //         this.$refs.loginForm.setErrors(error.response.data.error)
      //       })
      //   }
      // })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.img_logo {
  display: block;
  margin: 0 auto;
  max-width: 700px;
  width: 80%;
  height: auto;
}
.welcome {
  color: var(--foreground-color);
  font-weight: 500;
}
.sft_title {
  color: var(--accent3-color);
  font-weight: 600;
}
.signintxt {
  color: var(--foreground-color);
}
@media screen and (max-width: 992px) {
  .mainrowtxt {
    margin-top:25px;
  }
}
</style>
